/* eslint-disable max-lines-per-function -- includes theme vars */
/* eslint-disable no-restricted-properties */
import {
  ConditionalFormattingCellStyle,
  ConditionalFormattingGradientStyle,
  HexColor,
} from "@hex/common";
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization, no-restricted-imports
import { merge } from "lodash";
import type { editor as Editor } from "monaco-editor";
import { darken, lighten, mix, rgba } from "polished";
import { css } from "styled-components";

import sharedStyles, {
  generateMarkdownCodeTheme,
  generateMonacoRules,
  markdownStyles,
  monacoPillCss,
  pillCss,
} from "../common/sharedThemeStyles";
import type {
  BaseThemeVariables,
  CodeColorsType,
  PartialThemeVariables,
  Theme,
} from "../common/theme";

export const getLightModeVariables = (): BaseThemeVariables => {
  const ColorVars = sharedStyles.colors;
  const BrandColor = "#473982";

  const FontColor = {
    DEFAULT: ColorVars.DARK_GRAY1,
    MUTED: darken(0.03, ColorVars.GRAY1),
    PLACEHOLDER: ColorVars.GRAY3,
    LINK: ColorVars.BLUE2,
  };

  const BackgroundColor = {
    DEFAULT: ColorVars.WHITE,
    MUTED: ColorVars.OFF_WHITE,
    LOGIC: ColorVars.WHITE,
    DARK: rgba(ColorVars.LIGHT_GRAY5, 0.6),
    OVERLAY: rgba(ColorVars.OFF_WHITE, 0.8),
  };

  const BorderColor = {
    DEFAULT: ColorVars.LIGHT_GRAY2,
    INVERSE: ColorVars.WHITE,
    MUTED: ColorVars.LIGHT_GRAY4,
  };

  const IntentColors = {
    PRIMARY: BrandColor,
    SUCCESS: ColorVars.GREEN3,
    WARNING: ColorVars.GOLD2,
    DANGER: ColorVars.RED3,
  };

  return {
    BrandColor,
    ColorVars,
    FontColor,
    IntentColors,
    BackgroundColor,
    BorderColor,
    FontFamily: {
      DEFAULT: sharedStyles.fontFamily.DEFAULT,
      MONO: sharedStyles.fontFamily.MONO,
    },
    LetterSpacing: {
      DISPLAY: sharedStyles.letterSpacing.DISPLAY,
      HEADING: sharedStyles.letterSpacing.HEADING,
      MARKDOWN_HEADING: sharedStyles.letterSpacing.MARKDOWN_HEADING,
    },
  };
};

export const createLightMode = (overrides?: PartialThemeVariables): Theme => {
  const base = getLightModeVariables();

  const {
    BackgroundColor,
    BorderColor,
    BrandColor,
    ColorVars,
    FontColor,
    FontFamily,
    IntentColors,
    LetterSpacing,
  } = overrides ? merge(base, overrides) : base;

  const HoverColor = rgba(ColorVars.LIGHT_GRAY1, 0.15);
  /* Useful for situations where content may flow behind the hovered object. E.g. sticky tree component header */
  const NonTransparentHoverColor = lighten(0.13, ColorVars.LIGHT_GRAY1);
  const ActiveColor = ColorVars.LIGHT_GRAY5;

  const BoxShadow = {
    ACTION_BAR: `
    0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.6)}, 0 4px 10px 0 ${rgba(
      BorderColor.DEFAULT,
      0.15,
    )}, 0 8px 20px 0 ${rgba(BorderColor.DEFAULT, 0.25)};
    `,
    BUTTON: `
      inset 0 0 0 1px ${BorderColor.DEFAULT};`,
    CARD: `
        0 0 0 1px ${rgba(ColorVars.BLACK, 0.06)},
        0 1px 3px ${rgba(ColorVars.BLACK, 0.04)},
        0 2px 3px 0 ${rgba(ColorVars.BLACK, 0.03)};
    `,
    CARD_HOVER: `
        0 0 0 1px ${rgba(ColorVars.BLACK, 0.06)},
        0 3px 6px ${rgba(ColorVars.BLACK, 0.08)},
        0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.06)};
    `,
    CARD_FLAT_HOVER: `
      0 3px 6px 0 ${rgba(ColorVars.BLACK, 0.04)};
    `,
    CARD_SUBTLE: `
        0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.4)},
        0 1px 3px ${rgba(ColorVars.BLACK, 0.04)},
        0 2px 3px 0 ${rgba(ColorVars.BLACK, 0.02)};
    `,
    CARD_SUBTLE_HOVER: `
        0 0 0 1px ${rgba(BorderColor.DEFAULT, 0.5)},
        0 3px 6px ${rgba(ColorVars.BLACK, 0.03)},
        0 4px 8px 0 ${rgba(ColorVars.BLACK, 0.03)};
    `,
    CARD_SMALL: `
        0 0 0 1px ${rgba(ColorVars.BLACK, 0.1)},
        0 1px 1px 0 ${rgba(ColorVars.BLACK, 0.2)};
    `,
    CELL: `
      0 0 0 1px ${BorderColor.MUTED};
    `,
    CELL_HOVER: `
      0 0 0 1px ${BorderColor.MUTED};
    `,
    CELL_FOCUS: `
        0 0 0 1px ${BrandColor},
        0 0 0 2px ${rgba(BrandColor, 0.1)};
    `,
    CELL_SUCCESS: `
        0 0 0 1px ${ColorVars.GREEN3},
        0 0 0 3px ${rgba(ColorVars.GREEN4, 0.15)},
        0 8px 16px 0 ${rgba(ColorVars.BLACK, 0.05)},
        0 1px 4px 0 ${rgba(ColorVars.BLACK, 0.1)};
    `,
    CELL_WARNING: `
        0 0 0 1px ${ColorVars.GOLD3},
        0 0 0 3px ${rgba(ColorVars.GOLD4, 0.15)},
        0 8px 16px 0 ${rgba(ColorVars.BLACK, 0.05)},
        0 1px 4px 0 ${rgba(ColorVars.BLACK, 0.1)};
    `,
    CELL_DANGER: `
        0 0 0 1px ${ColorVars.RED3},
        0 0 0 3px ${rgba(ColorVars.RED4, 0.15)},
        0 8px 16px 0 ${rgba(ColorVars.BLACK, 0.05)},
        0 1px 4px 0 ${rgba(ColorVars.BLACK, 0.1)};
    `,
    COLOR_SWATCH: `
      inset 0 0 0 1px ${rgba(ColorVars.BLACK, 0.2)};
    `,
    COLOR_SWATCH_HOVER: `
        inset 0 0 0 1px ${rgba(ColorVars.BLACK, 0.4)},
        inset 0 0 0 3px ${rgba(ColorVars.BLACK, 0.12)};
    `,
    DIALOG: `
        0 0 0 1px ${rgba(ColorVars.BLACK, 0.1)},
        0 4px 8px ${rgba(ColorVars.BLACK, 0.07)},
        0 18px 66px 6px ${rgba(ColorVars.BLACK, 0.15)};
    `,
    EXPLORE_DROP_AREA: `
      0 0 6px 0 ${rgba(ColorVars.BLACK, 0.08)};
    `,
    FOCUS_PRIMARY: `
        0 0 0 0 ${rgba(ColorVars.BLACK, 0)},
        0 0 0 0 ${rgba(ColorVars.BLACK, 0)},
        inset 0 0 0 1px ${ColorVars.BLUE3},
        inset 0 0 0 3px ${rgba(ColorVars.BLUE3, 0.1)};
    `,
    FOCUS_WARNING: `
        0 0 0 1px ${ColorVars.GOLD3},
        0 0 0 3px ${rgba(ColorVars.GOLD3, 0.3)},
        inset 0 1px 1px ${rgba(ColorVars.GOLD3, 0.2)};
    `,
    FOCUS_ERROR: `
        0 0 0 1px ${ColorVars.RED3},
        0 0 0 3px ${rgba(ColorVars.RED3, 0.3)},
        inset 0 1px 1px ${rgba(ColorVars.RED3, 0.2)};
    `,
    FULL_PAGE_DIALOG_RED: `
      0px 8px 24px 0px ${rgba(ColorVars.RED3, 0.16)},
      0px 2px 4px 0px ${rgba(ColorVars.RED3, 0.12)},
      0px 0px 0px 1px ${rgba(ColorVars.RED3, 0.04)};
    `,
    FULL_PAGE_DIALOG_BLUE: `
      0px 8px 24px 0px ${rgba(ColorVars.BLUE4, 0.16)},
      0px 2px 4px 0px ${rgba(ColorVars.BLUE4, 0.12)},
      0px 0px 0px 1px ${rgba(ColorVars.BLUE4, 0.04)};
    `,
    FULL_PAGE_DIALOG_PURPLE: `
      0px 8px 24px 0px ${rgba(ColorVars.VIOLET3, 0.16)},
      0px 2px 4px 0px ${rgba(ColorVars.VIOLET3, 0.12)},
      0px 0px 0px 1px ${rgba(ColorVars.VIOLET3, 0.04)};
    `,
    INPUT: `
        inset 0 0 0 1px ${BorderColor.DEFAULT},
        inset 0 0 0 3px ${rgba(BrandColor, 0)};
    `,
    INPUT_FOCUS: `
        inset 0 0 0 1px ${BrandColor},
        inset 0 0 0 3px ${rgba(BrandColor, 0.2)};
    `,
    NAV_BAR: `0 1px 0 0 ${BorderColor.MUTED}`,
    POPOVER: `
        0 0 0 1px ${rgba(ColorVars.BLACK, 0.06)},
        0 3px 6px ${rgba(ColorVars.BLACK, 0.08)},
        0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.06)};
    `,
    SWITCH: `inset 0 0 0 1px ${BorderColor.DEFAULT}`,
    TOAST: `
        0 3px 6px ${rgba(ColorVars.BLACK, 0.08)},
        0 5px 10px 0 ${rgba(ColorVars.BLACK, 0.06)};
    `,
  };

  const PillColor = {
    RED: {
      backgroundColor: rgba(ColorVars.RED4, 0.1),
      color: ColorVars.RED2,
    },
    COBALT: {
      backgroundColor: rgba(ColorVars.COBALT4, 0.1),
      color: ColorVars.COBALT2,
    },
    VIOLET: {
      backgroundColor: rgba(ColorVars.VIOLET5, 0.12),
      color: ColorVars.VIOLET2,
    },
    INDIGO: {
      backgroundColor: rgba(ColorVars.INDIGO5, 0.12),
      color: ColorVars.INDIGO3,
    },
    FOREST: {
      backgroundColor: rgba(ColorVars.FOREST4, 0.1),
      color: ColorVars.FOREST2,
    },
    GREEN: {
      backgroundColor: rgba(ColorVars.GREEN4, 0.12),
      color: ColorVars.GREEN3,
    },
    GRAY: {
      backgroundColor: rgba(ColorVars.GRAY3, 0.12),
      color: ColorVars.DARK_GRAY5,
    },
    GRAY_HOVER: {
      backgroundColor: rgba(ColorVars.GRAY3, 0.12),
      color: ColorVars.BLACK,
    },
    WHITE: {
      backgroundColor: ColorVars.WHITE,
      color: ColorVars.GRAY1,
    },
    TURQUOISE: {
      backgroundColor: rgba(ColorVars.TURQUOISE4, 0.12),
      color: ColorVars.TURQUOISE1,
    },
  };

  const DragAndDrop = {
    selectedElementBorderColor: ColorVars.COBALT5,
    dragPreview: {
      backgroundNew: rgba(ColorVars.COBALT5, 0.07),
      backgroundOther: rgba(ColorVars.COBALT5, 0.03),
      borderColor: rgba(ColorVars.COBALT5, 0.4),
      border: `1px solid ${rgba(ColorVars.COBALT5, 0.4)}`,
      color: ColorVars.COBALT1,
      text: css`
        padding: 2px 5px;

        color: ${({ theme }) => theme.colors.COBALT1};

        background: ${({ theme }) => lighten(0.35, theme.colors.COBALT4)};
        border-radius: ${({ theme }) => theme.borderRadius};
      `,
    },
    dragPreviewBlocked: {
      background: rgba(ColorVars.RED5, 0.07),
      border: `1px solid ${rgba(ColorVars.RED5, 0.4)}`,
      color: ColorVars.RED1,
      text: css`
        padding: 2px 5px;

        color: ${({ theme }) => theme.colors.RED1};

        background: ${({ theme }) => lighten(0.35, theme.colors.RED4)};
        border-radius: ${({ theme }) => theme.borderRadius};
      `,
    },
  };

  const TableStyles: Theme["table"] = {
    activeCalcCellBackground: rgba(ColorVars.COBALT4, 0.06),
    activeCalcHeaderBackground: rgba(ColorVars.COBALT4, 0.06),
    activeCalcBorderColor: ColorVars.COBALT4,
    loadingGhostColor: rgba(ColorVars.DARK_GRAY5, 0.1),
    placeholderCellBackgroundColor: BackgroundColor.MUTED,
  };

  const CodeColors: CodeColorsType = {
    general: {
      DEFAULT: FontColor.DEFAULT,
      INVALID: ColorVars.RED3,
      SELECTION: ColorVars.LIGHT_GRAY3,
      IDENTIFIER: FontColor.DEFAULT,
      DELIMITER: FontColor.DEFAULT,
      COMMENT: FontColor.MUTED,
      BOOLEAN: ColorVars.BLUE3,
      STRING: ColorVars.RED3,
      KEYWORD: ColorVars.COBALT3,
      NUMBER: ColorVars.TURQUOISE2,
      TAG: ColorVars.RED3,
    },
    appView: {
      BACKGROUND: darken(0.01, BackgroundColor.MUTED),
    },
    html: {
      ATTRIBUTE: ColorVars.COBALT3,
      STRING: ColorVars.TURQUOISE2,
    },
    css: {
      DEFAULT: ColorVars.ORANGE3,
    },
    sql: {
      PREDEFINED: ColorVars.ROSE3,
    },
    calc: {
      COLUMN: ColorVars.GREEN2,
      PARAMETER_REFERENCE: PillColor.COBALT.color,
    },
  };

  /**
   * Custom monaco theme definition.
   *
   * Tokens can be inspected in a live editor using `F1 > Developer: Inspect Tokens`
   *
   * monaco color definitions: https://github.com/microsoft/vscode/blob/main/src/vs/platform/theme/common/colorRegistry.ts
   *                           https://github.com/microsoft/vscode/blob/main/src/vs/editor/common/view/editorColorRegistry.ts
   *
   * monaco token defintions: https://github.com/microsoft/vscode/blob/94c9ea46838a9a619aeafb7e8afd1170c967bb55/src/vs/workbench/services/themes/common/colorThemeSchema.ts#L14
   *
   * monaco base theme: https://github.com/Microsoft/vscode/blob/main/src/vs/editor/standalone/common/themes.ts
   */
  const LIGHT_MONACO_THEME: Editor.IStandaloneThemeData = {
    base: "vs",
    // see https://github.com/microsoft/monaco-editor/issues/1631 for values
    colors: {
      "editor.foreground": ColorVars.BLACK,
      "editorCursor.foreground": ColorVars.BLACK,
      "editor.selectionBackground": ColorVars.LIGHT_GRAY3,
      // For some reason these have to be hex values. If you use rgba it will
      // break and fall back to 0xFF0000.  AA = 0.66 opacity
      "editor.selectionHighlightBackground": `${ColorVars.LIGHT_GRAY5}AA`,
      "editor.inactiveSelectionBackground": `${ColorVars.LIGHT_GRAY5}AA`,
      "editor.wordHighlightBackground": `${ColorVars.LIGHT_GRAY5}AA`,
      // A bit lighter than the above colors so they don't run together
      "editorBracketMatch.background": `${ColorVars.LIGHT_GRAY5}77`,

      "editorIndentGuide.background": ColorVars.LIGHT_GRAY2,
      "editorIndentGuide.activeBackground": ColorVars.GRAY3,
      "editorLineNumber.foreground": ColorVars.GRAY4,
      "diffEditor.insertedTextBackground": `${ColorVars.GREEN5}33`, // 33 = 0.2 opacity
      "diffEditor.removedTextBackground": `${ColorVars.RED5}33`, // 33 = 0.2 opacity
      "diffEditor.diagonalFill": `${ColorVars.LIGHT_GRAY3}`,

      // Outline color for the entire editor
      focusBorder: BackgroundColor.DEFAULT,

      //////
      // Autocomplete colors
      //////

      // Background color of the suggestion widget
      "editorSuggestWidget.background": BackgroundColor.DEFAULT,

      // Foreground (default text) color of the suggestion widget.
      "editorSuggestWidget.foreground": FontColor.DEFAULT,

      // Color for inline completions
      "editorGhostText.foreground": FontColor.MUTED,

      ////////
      /// SELECTED ROWS

      // Color of the match highlights in the suggest widget when an item is focused.
      // The selected row
      "editorSuggestWidget.focusHighlightForeground": ColorVars.BLUE3,

      // Foreground color of the selected entry in the suggest widget.
      // This is the non-higlighted text part of the selected row
      // CC = 0.8 opacity
      "editorSuggestWidget.selectedForeground": FontColor.DEFAULT,

      // Background color of the selected entry in the suggestion widget.
      "editorSuggestWidget.selectedBackground": ColorVars.LIGHT_GRAY4,

      // Icon foreground color of the selected entry in the suggest widget.
      "editorSuggestWidget.selectedIconForeground": ColorVars.BLACK,

      ////////
      /// NOT SELECTED ROWS

      // Color of the match highlights in the suggestion widget.
      // The non-selected row
      "editorSuggestWidget.highlightForeground": ColorVars.BLUE3,

      // The background color of non-selected autocomplete results when hovered over with the mouse
      // Intended to match HoverColor (cannot use RGBA - see above)
      "list.hoverBackground": `${ColorVars.LIGHT_GRAY1}26`, // 26 = 0.15 opacity
    },
    inherit: true,
    rules: generateMonacoRules(CodeColors),
  };

  const MAGIC_COLORS = {
    backgroundGradient: `linear-gradient(
      270deg,
      rgba(81, 56, 140, 0.05) 0%,
      rgba(179, 115, 199, 0.03) 100%
    )`,
    backgroundColor: "#FCFBFD",
    primaryColor: "#51388C",
    secondaryColor: "#7E4FAA",
    tertiaryColor: "#B373C7",
  };

  const MAGIC_DIFF_COLORS = {
    diffInsertColor: `${ColorVars.GREEN5}0D`,
    diffDeleteColor: `${ColorVars.RED3}0D`,
    diffFillerColor: `${ColorVars.LIGHT_GRAY3}0D`,
  };

  const MAGIC_SHADOWS = {
    magicModalShadow: `
      0 0 0 1px ${rgba(MAGIC_COLORS.primaryColor, 0.2)},
      0 4px 8px ${rgba(MAGIC_COLORS.primaryColor, 0.07)},
      0 18px 66px 6px ${rgba(MAGIC_COLORS.primaryColor, 0.15)};`,
    promptBarShadow: `0 0 10px 0 ${rgba(MAGIC_COLORS.primaryColor, 0.1)};`,
  };
  return {
    ...sharedStyles,
    fontFamily: FontFamily,
    letterSpacing: LetterSpacing,

    isCustomTheme: false,
    type: "LIGHT",

    activeColor: ActiveColor,
    ansi: {
      BLACK_FG: ColorVars.BLACK,
      RED_FG: ColorVars.RED3,
      GREEN_FG: ColorVars.GREEN1,
      YELLOW_FG: ColorVars.GOLD2,
      BLUE_FG: ColorVars.COBALT3,
      MAGENTA_FG: ColorVars.ROSE3,
      CYAN_FG: ColorVars.TURQUOISE2,
      WHITE_FG: ColorVars.WHITE,
      BRIGHT_BLACK_FG: ColorVars.GRAY1,
      BRIGHT_RED_FG: ColorVars.VERMILION4,
      BRIGHT_GREEN_FG: ColorVars.GREEN3,
      BRIGHT_YELLOW_FG: ColorVars.GOLD2,
      BRIGHT_BLUE_FG: ColorVars.COBALT4,
      BRIGHT_MAGENTA_FG: ColorVars.ROSE4,
      BRIGHT_CYAN_FG: ColorVars.TURQUOISE2,
      BRIGHT_WHITE_FG: ColorVars.WHITE,
    },
    appBuilderRowHover: ColorVars.GRAY5,
    backgroundColor: BackgroundColor,
    bgTextureOpacity: 0.6,
    borderColor: BorderColor,
    boxShadow: BoxShadow,
    button: {
      default: {
        primary: {
          fontColor: BrandColor,
          backgroundColor: rgba(BrandColor, 0.05),
          borderColor: rgba(BrandColor, 0.2),
        },
        success: {
          fontColor: ColorVars.GREEN1,
          backgroundColor: ColorVars.GREEN2,
          borderColor: rgba(ColorVars.GREEN2, 0.2),
        },
        warning: {
          fontColor: ColorVars.GOLD1,
          backgroundColor: ColorVars.GOLD2,
          borderColor: rgba(ColorVars.GOLD2, 0.2),
        },
        danger: {
          fontColor: ColorVars.RED2,
          backgroundColor: ColorVars.RED3,
          borderColor: rgba(ColorVars.RED3, 0.2),
        },
        none: {
          fontColor: FontColor.DEFAULT,
          backgroundColor: BackgroundColor.DEFAULT,
          borderColor: BorderColor.DEFAULT,
        },
      },
      subtleOrMinimal: {
        primary: {
          fontColor: ColorVars.BLUE2,
          backgroundColor: ColorVars.BLUE4,
        },
        success: {
          fontColor: ColorVars.GREEN2,
          backgroundColor: ColorVars.GREEN4,
        },
        warning: {
          fontColor: ColorVars.GOLD1,
          backgroundColor: ColorVars.GOLD3,
        },
        danger: {
          fontColor: ColorVars.RED2,
          backgroundColor: ColorVars.RED4,
        },
        none: {
          fontColor: FontColor.DEFAULT,
          backgroundColor: ColorVars.GRAY4,
        },
      },
    },
    calculationsColor: {
      icon: ColorVars.COBALT4,
      background: rgba(ColorVars.COBALT4, 0.1),
    },
    codeBackground: rgba(ColorVars.LIGHT_GRAY5, 0.5),
    colorSwatchPlaceholder: ColorVars.LIGHT_GRAY3,
    componentsColor: {
      foreground: ColorVars.ROSE4,
      background: rgba(ColorVars.ROSE4, 0.1),
    },
    conditionalFormatting: {
      cell: {
        [ConditionalFormattingCellStyle.RED]: {
          backgroundColor: lighten(0.27, ColorVars.RED5),
          color: darken(0.1, ColorVars.RED1),
        },
        [ConditionalFormattingCellStyle.ORANGE]: {
          backgroundColor: lighten(0.28, ColorVars.ORANGE5),
          color: darken(0.1, ColorVars.ORANGE1),
        },
        [ConditionalFormattingCellStyle.YELLOW]: {
          backgroundColor: lighten(0.32, ColorVars.GOLD5),
          color: darken(0.1, ColorVars.GOLD1),
        },
        [ConditionalFormattingCellStyle.GREEN]: {
          backgroundColor: lighten(0.35, ColorVars.GREEN5),
          color: darken(0.1, ColorVars.GREEN1),
        },
        [ConditionalFormattingCellStyle.BLUE]: {
          backgroundColor: lighten(0.3, ColorVars.BLUE5),
          color: ColorVars.BLUE1,
        },
        [ConditionalFormattingCellStyle.PURPLE]: {
          backgroundColor: lighten(0.22, ColorVars.VIOLET5),
          color: ColorVars.VIOLET1,
        },
        [ConditionalFormattingCellStyle.GRAY]: {
          backgroundColor: lighten(0.12, ColorVars.GRAY5),
          color: ColorVars.DARK_GRAY1,
        },
        [ConditionalFormattingCellStyle.TURQUOISE]: {
          backgroundColor: lighten(0.35, ColorVars.TURQUOISE5),
          color: darken(0.12, ColorVars.TURQUOISE1),
        },
        [ConditionalFormattingCellStyle.LIME]: {
          backgroundColor: lighten(0.2, ColorVars.LIME5),
          color: darken(0.2, ColorVars.LIME1),
        },
        [ConditionalFormattingCellStyle.PINK]: {
          backgroundColor: lighten(0.27, ColorVars.ROSE5),
          color: darken(0.1, ColorVars.ROSE1),
        },
      },
      gradients: {
        [ConditionalFormattingGradientStyle.GREEN_WHITE]: {
          colors: [ColorVars.GREEN3, ColorVars.WHITE],
        },
        [ConditionalFormattingGradientStyle.BLUE_WHITE]: {
          colors: [ColorVars.BLUE3, ColorVars.WHITE],
        },
        [ConditionalFormattingGradientStyle.RED_WHITE]: {
          colors: [ColorVars.RED3, ColorVars.WHITE],
        },
        [ConditionalFormattingGradientStyle.WHITE_GREEN]: {
          colors: [ColorVars.WHITE, ColorVars.GREEN3],
        },
        [ConditionalFormattingGradientStyle.WHITE_BLUE]: {
          colors: [ColorVars.WHITE, ColorVars.BLUE3],
        },
        [ConditionalFormattingGradientStyle.WHITE_RED]: {
          colors: [ColorVars.WHITE, ColorVars.RED3],
        },
        [ConditionalFormattingGradientStyle.GREEN_WHITE_RED]: {
          colors: [ColorVars.GREEN3, ColorVars.WHITE, ColorVars.RED3],
        },
        [ConditionalFormattingGradientStyle.GREEN_YELLOW_RED]: {
          colors: [ColorVars.GREEN3, ColorVars.GOLD4, ColorVars.RED3],
        },
        [ConditionalFormattingGradientStyle.RED_YELLOW_GREEN]: {
          colors: [ColorVars.RED3, ColorVars.GOLD4, ColorVars.GREEN3],
        },
        [ConditionalFormattingGradientStyle.RED_WHITE_GREEN]: {
          colors: [ColorVars.RED3, ColorVars.WHITE, ColorVars.GREEN3],
        },
        [ConditionalFormattingGradientStyle.RED_WHITE_BLUE]: {
          colors: [ColorVars.RED3, ColorVars.WHITE, ColorVars.BLUE3],
        },
        [ConditionalFormattingGradientStyle.BLUE_WHITE_RED]: {
          colors: [ColorVars.BLUE3, ColorVars.WHITE, ColorVars.RED3],
        },
      },
    },
    dragAndDrop: DragAndDrop,
    fontColor: FontColor,
    globalCSSVars: css`
      --hex-animation-duration: ${({ theme }) => theme.animation.duration};
      --hex-animation-easing: ${({ theme }) => theme.animation.easing};
      --hex-font-family-default: ${({ theme }) => theme.fontFamily.DEFAULT};
      --hex-font-color-default: ${({ theme }) => theme.fontColor.DEFAULT};
      --hex-font-color-muted: ${({ theme }) => theme.fontColor.MUTED};
      --hex-font-color-placeholder: ${({ theme }) =>
        theme.fontColor.PLACEHOLDER};
      --hex-bkg-color-default: ${({ theme }) => theme.backgroundColor.DEFAULT};
      --hex-bkg-color-muted: ${({ theme }) => theme.backgroundColor.MUTED};
      --hex-bkg-color-selected: ${({ theme }) =>
        theme.menuItem.activeBackground};
      --hex-font-weight-normal: ${({ theme }) => theme.fontWeight.NORMAL};
      --hex-font-weight-medium: ${({ theme }) => theme.fontWeight.MEDIUM};
      --hex-font-weight-semi-bold: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
      --hex-font-size-default: ${({ theme }) => theme.fontSize.DEFAULT};
      --hex-font-size-small: ${({ theme }) => theme.fontSize.SMALL};
      --hex-font-size-extra-small: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
      --hex-icon-color: ${({ theme }) => theme.iconColor};
      --hex-border-radius: ${({ theme }) => theme.borderRadius};
      --hex-border-color-default: ${({ theme }) => theme.borderColor.DEFAULT};
      --hex-border-color-muted: ${({ theme }) => theme.borderColor.MUTED};
      --hex-box-shadow-popover: ${({ theme }) => theme.boxShadow.POPOVER};
      --hex-hover-color: ${HoverColor};
      --hex-intent-danger: ${ColorVars.RED3};
      --table-active-calc-border-color: ${({ theme }) =>
        theme.table.activeCalcBorderColor};
      --table-active-calc-background-color: ${({ theme }) =>
        theme.table.activeCalcCellBackground};
      --table-placeholder-cell-background-color: ${({ theme }) =>
        theme.table.placeholderCellBackgroundColor};
      --hex-table-range-selection-border-color: ${({ theme }) =>
        theme.colors.COBALT4};
      --hex-table-range-selection-background-color: ${({ theme }) =>
        rgba(theme.colors.COBALT4, 0.1)};
      --hex-table-range-selection-background-color-opaque: ${({ theme }) =>
        mix(0.9, BackgroundColor.DEFAULT, theme.colors.COBALT4)};
    `,
    graph: {
      dotColor: ColorVars.LIGHT_GRAY1,
      lineColor: ColorVars.GRAY5,
    },
    magic: { ...MAGIC_COLORS, ...MAGIC_DIFF_COLORS, ...MAGIC_SHADOWS },
    highlightColor: BrandColor,
    hoverColor: HoverColor,
    iconColor: ColorVars.GRAY1,
    intent: IntentColors,
    logoColor: BrandColor,
    markdownCodeTheme: (isInAppView, allowScroll) =>
      generateMarkdownCodeTheme(isInAppView, allowScroll, CodeColors),
    markdownStyles: (size) =>
      markdownStyles(size, {
        letterSpacingHeader: LetterSpacing.MARKDOWN_HEADING,
      }),
    menuItem: {
      activeText: BrandColor,
      activeBackground: rgba(BrandColor, 0.08),
    },
    monacoTheme: {
      name: "hexTheme-light",
      options: LIGHT_MONACO_THEME,
    },
    monacoPill: {
      RED: {
        ...PillColor.RED,
        css: pillCss(PillColor.RED),
      },
      COBALT: {
        ...PillColor.COBALT,
        css: monacoPillCss(PillColor.COBALT),
      },
      VIOLET: {
        ...PillColor.VIOLET,
        css: monacoPillCss(PillColor.VIOLET),
      },
      INDIGO: {
        ...PillColor.INDIGO,
        css: monacoPillCss(PillColor.INDIGO),
      },
      FOREST: {
        ...PillColor.FOREST,
        css: monacoPillCss(PillColor.FOREST),
      },
      GREEN: {
        ...PillColor.GREEN,
        css: monacoPillCss(PillColor.GREEN),
      },
      GRAY: {
        ...PillColor.GRAY,
        css: monacoPillCss(PillColor.GRAY_HOVER),
      },
      WHITE: {
        ...PillColor.WHITE,
        css: css`
          box-shadow: inset 0 0 0 1px ${BorderColor.DEFAULT};
          ${monacoPillCss(PillColor.WHITE)}
        `,
      },
      TURQUOISE: {
        ...PillColor.TURQUOISE,
        css: monacoPillCss(PillColor.TURQUOISE),
      },
    },
    NonTransparentHoverColor: NonTransparentHoverColor,
    pill: {
      RED: {
        ...PillColor.RED,
        css: pillCss(PillColor.RED),
      },
      COBALT: {
        ...PillColor.COBALT,
        css: pillCss(PillColor.COBALT),
      },
      VIOLET: {
        ...PillColor.VIOLET,
        css: pillCss(PillColor.VIOLET),
      },
      INDIGO: {
        ...PillColor.INDIGO,
        css: pillCss(PillColor.INDIGO),
      },
      FOREST: {
        ...PillColor.FOREST,
        css: pillCss(PillColor.FOREST),
      },
      GREEN: {
        ...PillColor.GREEN,
        css: pillCss(PillColor.GREEN),
      },
      GRAY: {
        ...PillColor.GRAY,
        css: pillCss(PillColor.GRAY),
      },
      WHITE: {
        ...PillColor.WHITE,
        css: css`
          box-shadow: inset 0 0 0 1px ${BorderColor.MUTED};
          ${pillCss(PillColor.WHITE)}
        `,
      },
      TURQUOISE: {
        ...PillColor.TURQUOISE,
        css: pillCss(PillColor.TURQUOISE),
      },
    },
    searchHighlightColor: rgba(ColorVars.GOLD5, 0.2),
    searchHighlightFocusColor: rgba(ColorVars.GOLD5, 0.5),
    statusCategoryColor: {
      [HexColor.RED]: ColorVars.RED2,
      [HexColor.ORANGE]: ColorVars.ORANGE1,
      [HexColor.YELLOW]: ColorVars.GOLD2,
      [HexColor.GREEN]: ColorVars.FOREST1,
      [HexColor.TEAL]: ColorVars.TURQUOISE1,
      [HexColor.BLUE]: ColorVars.COBALT2,
      [HexColor.PURPLE]: ColorVars.INDIGO1,
      [HexColor.PINK]: ColorVars.ROSE3,
      [HexColor.GRAY]: ColorVars.GRAY1,
      [HexColor.LIME]: ColorVars.LIME1,
    },
    stepper: {
      activeStepBackgroundColor: BrandColor,
      inactiveStepBackgroundColor: "transparent",
      activeStepFontColor: ColorVars.WHITE,
      inactiveStepFontColor: FontColor.MUTED,
    },
    CalloutOrToastBackground: {
      /* This produces the same visual result as rgba(intent color, 0.05)
      but without transparency which we don't want for toasts */
      primary: mix(0.95, BackgroundColor.DEFAULT, IntentColors.PRIMARY),
      success: mix(0.95, BackgroundColor.DEFAULT, IntentColors.SUCCESS),
      warning: mix(0.95, BackgroundColor.DEFAULT, IntentColors.WARNING),
      danger: mix(0.95, BackgroundColor.DEFAULT, IntentColors.DANGER),
      none: BackgroundColor.DEFAULT,
    },
    tour: {
      accentColor: rgba(ColorVars.WHITE, 0.6),
      background: "linear-gradient(6.08deg, #2E2B48 9.5%, #49426E 88.68%)",
      fontColor: ColorVars.WHITE,
      mutedFontColor: rgba(ColorVars.WHITE, 0.6),
      pulsingIndicatorInnerColor: ColorVars.WHITE,
      pulsingIndicatorMiddleColor: BrandColor,
      pulsingIndicatorOuterColor: BrandColor,
    },
    bento: {
      jumpBackIn: BrandColor,
      popular: IntentColors.SUCCESS,
      // TODO CORE2-712 need page theme variables
      collections: ColorVars.BLUE4,
      recentlyPublished: ColorVars.ORANGE3,
      learn: ColorVars.GOLD3,
    },
    table: TableStyles,
    useInvertedConnectionIcons: false,
    userActionColor: ColorVars.GOLD3, // For comments and stars
    vegaTooltipTheme: "light",
    unreadIndicator: {
      backgroundColor: BrandColor,
      fontColor: BackgroundColor.DEFAULT,
    },
  };
};

export const LIGHT_THEME: Theme = createLightMode();

export const useLightTheme = (): Theme => {
  return LIGHT_THEME;
};
